import React, { FunctionComponent, Fragment } from 'react'
import Landing from 'components/landing'

const App: FunctionComponent = () => {
  return (
    <Fragment>
      <Landing />
    </Fragment>
  )
}

export default App
