import React, { FunctionComponent } from 'react'

import Logo from 'atoms/logo'
import styles from './styles.module.scss'

type LandingProps = {}

const Landing: FunctionComponent<LandingProps> = () => {
  return (
    <div className={styles.landing}>
      <Logo />
      <div className={styles.subtitle}>Money Is Not A Problem</div>
      <div className={styles.signup}>
        <div
          className={styles.connect}
          onClick={e => {
            e.preventDefault()
            ;(window as any).indemandly.openWidget()
          }}
        >
          Connect
        </div>
      </div>
    </div>
  )
}

export default Landing
